import { Box, useMediaQuery } from "@mui/material";

import DashboardCardsSection from "./DashboardCardsSection";
import DashboardTable from "./DashboardTable";

interface DashboardSectionProps {
  loading: boolean;
  boxId: string;
  sectionTitle: string;
  tableContent: any;
}

const DashboardSection = ({
  loading,
  boxId,
  sectionTitle,
  tableContent,
}: DashboardSectionProps) => {
  const isMobile = useMediaQuery("(max-width: 1000px)");
  return (
    <Box id={boxId} mr={{ xs: 0 }} border={1} borderRadius={2} mt={4}>
      {isMobile ? (
        <DashboardCardsSection
          searchTerm={""}
          loading={loading}
          title={sectionTitle}
          cardData={tableContent}
        />
      ) : (
        <DashboardTable
          title={sectionTitle}
          tableData={tableContent}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default DashboardSection;
