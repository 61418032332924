import { useState } from "react";

import { Box, Typography } from "@mui/material";

import PortalTable from "shared/components/table/PortalTable";

const sortByKey = (array: any[], key: string, desc?: string): any[] => {
  const arr: any = array;
  return arr.sort((a: any, b: any) => {
    const x = Number(a[key]) || a[key];
    const y = Number(b[key]) || b[key];
    if (desc == "asc") return x > y ? -1 : x < y ? 1 : 0;
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export default function DashboardTable({
  title,
  tableData,
  loading,
}: {
  title: string;
  tableData: any;
  loading: boolean;
}) {
  const [orderBy, setOrderBy] = useState(tableData.orderBy);
  const [direction, setDirection] = useState(tableData.direction);

  const dashTableData = tableData;
  dashTableData.data = sortByKey(
    dashTableData.data,
    orderBy,
    dashTableData.direction
  );

  const handleSortClick = (column: string) => {
    const newDirection = direction === "asc" ? "desc" : "asc";
    setDirection(newDirection);
    setOrderBy(column);
    dashTableData.orderBy = column;
    dashTableData.direction = newDirection;
    dashTableData.data = sortByKey(dashTableData.data, column, newDirection);
  };

  return (
    <Box mx={2} mt={2} mb={4}>
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <PortalTable
        data={dashTableData}
        handleSort={handleSortClick}
        loading={loading}
        searchTerm={""}
        heightOffset={-200}
      />
    </Box>
  );
}
