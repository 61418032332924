import { Box, Card, CardContent, Typography } from "@mui/material";

export default function InfoCard({
  title,
  info,
  color,
  backgroundColor,
}: {
  title: string;
  info: string | number;
  color?: string;
  backgroundColor?: string;
}) {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      mx={1}
      my={{ xs: 0.5, md: 0 }}
      width={"100%"}
    >
      <Card sx={{ width: "100%", color, backgroundColor }}>
        <CardContent>
          <Typography
            borderBottom={2}
            mb={1}
            borderColor="accent1.main"
            variant="h6"
          >
            {title}
          </Typography>
          <Typography fontWeight={600} variant="h5">
            {info}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
