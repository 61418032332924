import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const PeriodFilter = ({ rangeKey, onRangeChanged }: any) => {
  return (
    <Box mb={2} display={"flex"} justifyContent={"space-between"}>
      <Box mb={2} mt={-0.5}>
        <Typography variant="h4">This Period</Typography>
      </Box>
      <Box>
        <FormControl>
          <InputLabel color="secondary" id="period-select-label">
            Period
          </InputLabel>
          <Select
            size="small"
            color="secondary"
            labelId="period-select-label"
            id="period-select"
            value={rangeKey}
            label="Period"
            onChange={onRangeChanged}
          >
            <MenuItem value={"month_to_date"}>Month to Date</MenuItem>
            <MenuItem value={"quarter_to_date"}>Quarter to Date</MenuItem>
            <MenuItem value={"year_to_date"}>Year to Date</MenuItem>
            <MenuItem value={"last_month"}>Last Month</MenuItem>
            <MenuItem value={"last_quarter"}>Last Quarter</MenuItem>
            <MenuItem value={"all_time"}>All Time</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default PeriodFilter;
