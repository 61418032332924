import { Box, Card, CardContent, Typography } from "@mui/material";

interface DashboardCardsSectionProps {
  title: string;
  loading: boolean;
  searchTerm: string;
  cardData: any;
}

const DashboardCardsSection = ({
  title,
  loading,
  searchTerm,
  cardData,
}: DashboardCardsSectionProps) => {
  const data = cardData.data.map((data: any) => {
    return Object.values(data);
  });

  const columns = Object.values(cardData.columns).flatMap(
    (arr: any) => arr.name
  );

  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const isPrice = (n: any) => {
    const stringNumber = n.toString();
    const regExp = new RegExp(/^\d+\.\d{0,2}$/);
    return regExp.test(stringNumber);
  };

  return (
    <Box mx={2} mt={2} mb={4}>
      <Box>
        <Typography sx={{ mb: 2 }} variant="h6">
          {title}
          <hr />
        </Typography>
        {loading ? (
          <Typography textAlign={"center"}>Loading...</Typography>
        ) : (
          <>
            {!loading && cardData?.data.length > 0 ? (
              <>
                {data.map((data: any, index: number) => {
                  return (
                    <Card key={index}>
                      <CardContent>
                        {columns.map((col: any, colIndex: number) => {
                          return (
                            <>
                              <Typography
                                key={colIndex}
                                sx={{ fontWeight: "bold" }}
                              >
                                {col}:
                              </Typography>
                              <Typography key={colIndex} mb={1.5} pl={2}>
                                {isPrice(data[colIndex])
                                  ? priceFormatter.format(data[colIndex])
                                  : data[colIndex]}
                              </Typography>
                            </>
                          );
                        })}
                      </CardContent>
                    </Card>
                  );
                })}
              </>
            ) : (
              <Card>
                <CardContent>
                  <Typography textAlign={"center"}>
                    {searchTerm === ""
                      ? "Currently there is no data available"
                      : "No data like " + searchTerm}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DashboardCardsSection;
